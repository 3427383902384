<template>
    <div
        class="tw-absolute tw-bottom-full tw-left-0 tw-z-20 tw-rounded-[2rem] tw-bg-gray-300/90 tw-p-1"
    >
        <ul class="tw-flex tw-flex-wrap tw-items-center">
            <li
                v-for="(reaction, index) in availableReactions"
                :class="$style.listItem"
                :key="index"
                :style="{
                    animationDelay: `${index * 0.05}s`,
                }"
            >
                <button
                    type="button"
                    :class="[
                        'tw-px-2 tw-py-1',
                        $style.emojiButton,
                        activeIndex === index ? $style.emojiButtonActive : '',
                    ]"
                    :data-title="reaction.title"
                    @click="emit('react', reaction)"
                >
                    <span class="tw-text-4xl hover:tw-text-4xl">
                        {{ reaction.emoji }}
                    </span>
                </button>
            </li>
        </ul>
    </div>
</template>

<script setup>
const props = defineProps({
    availableReactions: {
        type: Array,
        required: true,
    },
    activeIndex: Number,
})

const emit = defineEmits(['react'])
</script>

<style lang="scss" module>
@keyframes bounce {
    0% {
        transform: translateY(25%);
        opacity: 0;
    }

    50% {
        transform: translateY(-25%);
        opacity: 1;
    }

    100% {
        transform: translateY(0);
        opacity: 1;
    }
}

.listItem {
    animation: bounce 0.3s ease-in-out forwards;
    opacity: 0;
}

.emojiButton {
    transition:
        transform 0.2s ease,
        opacity 0.3s ease;
    transform-origin: bottom;
    opacity: 1;

    &::after {
        content: attr(data-title);
        position: absolute;
        bottom: 100%;
        left: 50%;
        transform: translateX(-50%);
        display: none;
        line-height: 0.6rem;
        padding: 0.3rem 0.4rem 0.3rem 0.4rem;
        background-color: rgb(45, 166, 217);
        color: #fff;
        border-radius: 0.2rem;
        font-size: 0.5rem;
        font-style: italic;
    }
    &::before {
        content: '';
        position: absolute;
        bottom: 100%;
        background-color: rgb(45, 166, 217);
        width: 4px;
        height: 4px;
        left: 50%;
        transform: translate(-50%, 2px) rotate(45deg);
        display: none;
    }

    @media (hover: hover) {
        &:hover,
        &.emojiButtonActive {
            transform: scale(1.5);
            opacity: 1;

            &::after,
            &::before {
                display: block;
            }
        }
    }

    &.emojiButtonActive {
        transform: scale(1.5);
        opacity: 1;

        &::after,
        &::before {
            display: block;
        }
    }
}

.bounce {
    animation: bounce 0.3s ease-in-out forwards;
    opacity: 0;
}
</style>
