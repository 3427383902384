import { currentHeaderHeight } from '../modules/header/header.js'

export const showBlind = function (element, duration = null, display = 'block') {
    const currentStyle = window.getComputedStyle(element)

    const cleanupStyle = {
        paddingTop: currentStyle.paddingTop,
        paddingBottom: currentStyle.paddingBottom,
        minHeight: currentStyle.minHeight,
    }
    if (duration !== null) {
        element.style.transitionProperty =
            currentStyle.transitionProperty + ', height, min-height, padding'
        element.style.transitionDuration = duration + 'ms'
    }

    const willChange =
        currentStyle.willChange === 'auto'
            ? []
            : [...currentStyle.willChange.split(',').map((property) => property.trim())]
    if (!willChange.includes('height')) {
        willChange.push('height')
    }
    if (!willChange.includes('padding')) {
        willChange.push('padding')
    }
    if (!willChange.includes('min-height')) {
        willChange.push('min-height')
    }
    element.style.willChange = willChange.join(',')
    element.style.height = '0'
    element.style.minHeight = '0'
    element.style.paddingTop = '0'
    element.style.paddingBottom = '0'
    element.style.display = display
    element.style.overflow = 'hidden'

    return new Promise((resolve) => {
        window.requestAnimationFrame(() => {
            const expandedHeight =
                element.scrollHeight +
                parseInt(cleanupStyle.paddingTop) +
                parseInt(cleanupStyle.paddingBottom)

            element.style.display = null
            element.style.height = expandedHeight + 'px'
            element.style.minHeight = cleanupStyle.minHeight
            element.style.paddingTop = cleanupStyle.paddingTop
            element.style.paddingBottom = cleanupStyle.paddingBottom

            const cleanup = () => {
                element.style.minHeight = null
                element.style.overflow = null
                element.style.height = null
                element.style.paddingTop = null
                element.style.paddingBottom = null
                element.removeAttribute('hidden')
                resolve(element)
            }
            if (currentStyle.transitionDuration !== '0s') {
                const onEnd = () => {
                    cleanup()
                    element.removeEventListener('transitionend', onEnd)
                }
                element.addEventListener('transitionend', onEnd)
            } else {
                cleanup()
            }
        })
    })
}

export const hideBlind = function (element, duration = null) {
    const currentStyle = window.getComputedStyle(element)

    if (duration !== null) {
        element.style.transitionProperty =
            currentStyle.transitionProperty + ', height, min-height, padding'
        element.style.transitionDuration = duration + 'ms'
    }
    const willChange =
        currentStyle.willChange === 'auto'
            ? []
            : [...currentStyle.willChange.split(',').map((property) => property.trim())]
    if (!willChange.includes('height')) {
        willChange.push('height')
    }
    if (!willChange.includes('padding')) {
        willChange.push('padding')
    }
    if (!willChange.includes('min-height')) {
        willChange.push('min-height')
    }
    element.style.height = '0'
    element.style.minHeight = '0'
    element.style.paddingTop = '0'
    element.style.paddingBottom = '0'
    element.style.overflow = 'hidden'

    return new Promise((resolve) => {
        const cleanup = () => {
            element.style.minHeight = null
            element.style.overflow = null
            element.style.height = null
            element.style.paddingTop = null
            element.style.paddingBottom = null
            element.style.display = 'none'
            element.setAttribute('hidden', '')
            resolve(element)
        }

        if (currentStyle.transitionDuration !== '0s') {
            const onEnd = () => {
                cleanup()
                element.removeEventListener('transitionend', onEnd)
            }
            element.addEventListener('transitionend', onEnd)
        } else {
            cleanup()
        }
    })
}

export const fadeOut = (element, duration = 400) => {
    return new Promise((resolve) => {
        let opacity = 1
        const intervalId = setInterval(() => {
            opacity -= 0.05
            element.style.opacity = opacity
            if (opacity <= 0) {
                clearInterval(intervalId)
                element.style.display = 'none'
                resolve()
            }
        }, duration / 20)
    })
}

window.hideBlind = hideBlind

export const scrollTo = (x, y) => {
    return window.scrollTo(x, Math.max(0, y - currentHeaderHeight()))
}

/**
 * @param {string|HTMLElement} target
 * @param {number|null} duration
 * @param {object} options
 */
export const slideTo = (target, duration = null, options = {}) => {
    if (typeof target === 'string') {
        target = window.document.querySelector(target)
    }

    if (target === null || (typeof target !== 'object' && typeof target !== 'number')) {
        throw new Error('Invalid target.')
    }

    let top = 0
    if (typeof target === 'number') {
        top = target + (options.offset || 0)
    } else {
        const rect = target.getBoundingClientRect()
        const scrollY =
            window.scrollY || document.body.scrollTop || document.documentElement.scrollTop

        top = rect.top + scrollY + (options.offset || 0) - currentHeaderHeight(true)
    }

    if (duration !== null) {
        console.warn('Duration is no longer supported.')
    }

    const defaultOptions = {
        behavior: 'smooth',
        top: top,
    }

    return window.scrollTo({ ...defaultOptions, ...options })
}
